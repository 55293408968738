export const journeyNavBar = [
  {
    status: "Quote Journey",
    selector: true,
    name: "quoteJourney",
  },

  {
    status: "Chat",
    selector: false,
    name: "chat",
  },
  {
    status: "Quote History",
    selector: false,
    name: "quoteHistory",
  },
];

export const workFlowStatus = [
  {
    status: "Upload Group",
    callApi: false,
    name: "Group",
    revise: false,
  },
  {
    status: "Upload MAF",
    callApi: false,
    name: "Maf",
    revise: false,
  },
  {
    status: "Upload MAF Group",
    callApi: false,
    name: "MafGroup",
    revise: false,
  },
  {
    status: "Send Quote",
    callApi: true,
    name: "sendQuote",
    revise: false,
  },
  {
    status: "Refer Insurer",
    callApi: true,
    name: "referInsurer",
    revise: false,
  },
  {
    status: "Ask Further Documents",
    callApi: true,
    name: "askFurtherDocuments",
    revise: false,
  },
  {
    status: "Revise Premium",
    callApi: true,
    name: "revisePremium",
    revise: true,
  },
  {
    status: "Submit Documents",
    callApi: true,
    name: "submittedDocments",
    revise: false,
  },
  {
    status: "Refer Insurer For Discount",
    callApi: true,
    name: "referInsurerForDiscount",
    revise: false,
  },
  {
    status: "Refer Reinsurer For Discount",
    callApi: true,
    name: "referReinsurerForDiscount",
    revise: false,
  },
  {
    status: "Submit Reinsurer Documents",
    callApi: true,
    name: "submittedReInsurerDocments",
    revise: false,
  },
  {
    status: "Customer Accept",
    callApi: false,
    name: "customerAccepted",
    revise: false,
  },
  {
    status: "Proceed for Policy Issuance",
    callApi: false,
    name: "proceedForPolicyIssuance",
    revise: false,
  },
];

export const selectedWorkFlowName = [
  {
    flowName: "customer details",
    path: "basic_details",
  },
  {
    flowName: "quote details",
    path: "quotes",
  },
  {
    flowName: "maf filled",
    path: "maf-risk",
  },
  {
    flowName: "Documents",
    path: "document",
  },
  {
    flowName: "revised quote",
    path: "bounded-quote",
    status: "bounded",
  },
  {
    flowName: "Policy Issued",
    path: "",
  },
  {
    flowName: "Discounts",
    path: "refer-discounts",
  },
];

export const focusVariable = [
  {
    focus: "chat",
    status: "Refer Insurer",
  },
  {
    focus: "editPremium",
    status: "",
  },
  {
    focus: "askDocuments",
    status: "",
  },
];

export const nextAction = [
  {
    flow: "quote_generated",
    action: ["Quote Sent"],
  },
  {
    flow: "quote_sent",
    action: ["Quote Sent"],
  },
];

export const editPremiumValuesActions = [
  {
    riskType: "group",
    quoteType: "indicative",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: true,
      censusPremium: false,
    },
  },
  {
    riskType: "maf",
    quoteType: "indicative",
    insurer:"newton",
    allowAction: {
      optionPremium: false,
      categoryPremium: false,
      downloadDocuments: true,
      censusPremium: true,
    },
  },
  {
    riskType: "maf_group",
    quoteType: "indicative",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: true,
      censusPremium: true,
    },
  },
  {
    riskType: "group",
    quoteType: "binding",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: false,
      censusPremium: false,
    },
  },
  {
    riskType: "maf_group",
    quoteType: "binding",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: false,
      censusPremium: false,
    },
  },
  {
    riskType: "maf",
    quoteType: "binding",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: false,
      censusPremium: false,
    },
  },
  {
    riskType: "no",
    quoteType: "binding",
    allowAction: {
      optionPremium: true,
      categoryPremium: true,
      downloadDocuments: false,
      censusPremium: false,
    },
  },
];

export const companyDocuments = [
  {
    name: "Vat certificate",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Trade License certificate",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Signed and stamped quotation",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Updated census list",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Ministry of labour list",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Proof of continuity of Health Insurance cover ",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
  {
    name: "Company owner’s/POA ",
    isMandatory: true,
    type: "pdf",
    errorMessage: "",
    icon: "assets/icons/upload.svg",
  },
];
export const reinsurerNameList = ["ar", "ici"];

export const insurerUrlConfig = [
  {
    insurer: "insurancehouse",
  },
  {
    insurer: "abnic",
  },
  {
    insurer: "demo",
  },
  {
    insurer: "staging",
  },
  {
    insurer: "orientunbtakaful",
  },
  {
    insurer: "qic",
  },
  {
    insurer: "orientinsurance",
  },
];

export const distributorCofiguration = [
  {
    journey: "Company Configurations",
    description: "companyConfig",
    selector: false,
    router: "main/company-details",
    key: 'company-configuration'
  },

  {
    journey: "Manage Documentation",
    description: "manage-documents",
    selector: false,
    router: "main/manage-document",
    key: 'manage-documentation'
  },
];

export const documentationConfiguration = [
  {
    journey: "Exclusion List",
    description: "exclusion",
    selector: false,
    router: "main/manage-document/exclusion",
  },
  {
    journey: "Network List",
    description: "network",
    selector: false,
    router: "main/manage-document/network",
  },
  {
    journey: "Document templates",
    description: "maf",
    selector: false,
    router: "main/manage-document/documents",
  },
  // {
  //   journey:'MAF Document',
  //   description:'maf',
  //   selector:false,
  //   router:'main/manage-document/maf-docs'
  // },
  // {
  //   journey:'Group Documents',
  //   description:'group',
  //   selector:false,
  //   router:'main/manage-document/group-docs'
  // }
];

export const downloadDocumentation = [
  {
    name: "Download MAF",
    key: "maf",
    documentName: "",
    columnName: "maf_document",
  },
  {
    name: "Download Group",
    key: "group",
    documentName: "",
    columnName: "group_document",
  },
];

export const headers = ["Gender", "Marital Status", "Member Type", "Nationality", "Relationship", "Visa Issuance Emirates", "Category", "Employee Name"];

export const requirementType = [
  {
    name: "Download",
    selector: false,
  },
  {
    name: "Upload",
    selector: false,
  },
  {
    name: "Both",
    params: "Both",
    selector: false,
  },
];

export const projectGuideLines = {
  rules: [
    "Minimum – 5 employees up to Maximum 250 members.",
    "Individual Application Form mandatory for all groups up to 20 members.",
    "Each Category – minimum members will be 3 Employees.",
    "Above 250 member – refer to UW.",
    "No anti-selection in terms of categorization and benefit selection (i.e. Maternity, Dental & Optical benefits cannot be altered for individual members) – All members in a Category should have the same level of benefits.",
    "All medical declarations with any adverse findings will be underwritten & additional premium loading will be applied.",
  ],
};

export const insurerSidePanel = [
  {
    icon: "assets/icons/diactive-quote.png",
    selector: false,
    activeIcon: "assets/icons/active-quote.png",
    name: "quote",
    tooltipHint: "Dashboard",
  },
  {
    icon: "assets/icons/main-deactive.png",
    selector: false,
    activeIcon: "assets/icons/active-main.png",
    name: "dashboard",
    tooltipHint: "Configurations",
  },
  {
    icon: "assets/icons/disable-gen-quo.png",
    selector: false,
    activeIcon: "assets/icons/active-gen-qu.png",
    name: "products",
    tooltipHint: "View quotes",
  },
];

export const reInsurerSidePanel = [
  {
    icon: "assets/icons/main-deactive.png",
    selector: false,
    activeIcon: "assets/icons/active-main.png",
    name: "re-dashboard",
    tooltipHint: "Dashboard",
  },
];

export const reInsurerNavItems = [
  {
    name: "Manage Insurers",
    selector: true,
    templateName: "manageInsurer",
  },
  {
    name: "Create Users",
    selector: false,
    templateName: "createUsers",
  },
  {
    name: "Analytics",
    selector: false,
    templateName: "analytics",
  },
  // {
  //   name: "Role Configuration",
  //   selector: false,
  //   templateName: "ruleConfiguration",
  // }
];

export const analyticsHeader = [
  {
    text: "S.No",
  },
  {
    text: "Insurer ",
  },
  {
    text: "Quote Count",
  },

  {
    text: "Policy Count",
  },

  {
    text: "Premium",
  }
]
export const brokerCompanyHeaders = [
  {
    text: "S.No",
  },
  {
    text: "Broker Company",
  },
  {
    text: "Quote Count",
  },

  {
    text: "Policy Count",
  },

  {
    text: "Premium",
  }
]
export const brokerCompanyAgentsHeaders = [
  {
    text: "S.No",
  },
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Email Id",
  },
  {
    text: "Quote Count",
  },

  {
    text: "Policy Count",
  },

  {
    text: "Premium",
  }
]
export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const routePathForPeakre = {
  insurancehouse: '01',
  aiaw: '01',
  awnic: '01',
  abnic: '01',
  dnic: '01',
  orienttakaful: '01',
  nlgi: '06',
  dic: '01',
  rak: '01',
  qic: '04',
  takafulemarat: '01',
  demo: '01',
  staging: '01',
  newton: '02',
  orientinsurance: '01',
  'axa-demo': '02',
  'qic-ri': '03',
  dni:'05',
  orient : '08',
  'nascore-insurer':'09',
  dohatakaful: '01',
  'medgulf-insurer': '10',
  'staging-test':'01',
  'axa-dni': '01',
  'watania-takaful':'12',
  fidelity:'13',
  swissre:'14',
  enaya:'16'
}

export const tpaNavTabsJourney = [{
  status:'plan',
  name: 'Plan',
  selector:true
},{
  name: 'Underwriting rules',
  status:'rules',
  selector:false,
  router:'reinsurer-v1/manage-emirates/insurer-underwrite-rules'
},{
  name: 'Commissions',
  status:'ceding',
  selector:false,
  router:'reinsurer-v1/manage-emirates/ceding-commission'
},{
  name: 'Guidelines',
  status:'guidelines',
  selector:false,
  router:'reinsurer-v1/manage-emirates/guidelines'
}]


export const industryData = [
  {
      "industry_master_id": 8,
      "industry_name": "Other  Industries",
      "allows": "true"
  },
  {
      "industry_master_id": 9,
      "industry_name": "Personnel working in mines and other underground worker, Quarrymen",
      "allows": "false"
  },
  {
      "industry_master_id": 10,
      "industry_name": "Ship crews",
      "allows": "false"
  },
  {
      "industry_master_id": 11,
      "industry_name": "Airline personnel and aircrews",
      "allows": "false"
  },
  {
      "industry_master_id": 12,
      "industry_name": "Professional sport teams",
      "allows": "false"
  },
  {
      "industry_master_id": 13,
      "industry_name": "Personnel working on oil rigs",
      "allows": "false"
  },
  {
      "industry_master_id": 14,
      "industry_name": "Personnel working offshore, in or under water",
      "allows": "false"
  },
  {
      "industry_master_id": 15,
      "industry_name": "Regular armed forces. military or Police",
      "allows": "false"
  },
  {
      "industry_master_id": 16,
      "industry_name": "Firemen",
      "allows": "false"
  },
  {
      "industry_master_id": 17,
      "industry_name": "Professional Entertainers / Artists",
      "allows": "false"
  },
  {
      "industry_master_id": 18,
      "industry_name": "Group policies covering Syndicates, Unions, Associations, Societies, Councils, Medical Providers (Doctors, Clinics, Hospitals, Labs, Pharmacies, Medical Centres, Ophthalmologists, Dentist etc.) Public Sector and Governmental institutions",
      "allows": "false"
  },
  {
      "industry_master_id": 19,
      "industry_name": "Private and governmental schools as well as universities and any kind of education centres;",
      "allows": "false"
  },
  {
      "industry_master_id": 20,
      "industry_name": "Hotels",
      "allows": "false"
  },
  {
      "industry_master_id": 21,
      "industry_name": "Banks",
      "allows": "false"
  },
  {
      "industry_master_id": 22,
      "industry_name": "Embassies",
      "allows": "false"
  },
  {
      "industry_master_id": 23,
      "industry_name": "Sport Clubs",
      "allows": "false"
  },
  {
      "industry_master_id": 24,
      "industry_name": "Chamber of Commerce",
      "allows": "false"
  },
  {
      "industry_master_id": 25,
      "industry_name": "Regular armed forces, police force and air crews",
      "allows": "false"
  },
  {
      "industry_master_id": 26,
      "industry_name": "Air and sea travel except as a passenger only for coverage abroad (outside Jordan) Travel agencies and their employees only for coverage abroad (outside Jordan) Travel card, charge card and credit card business",
      "allows": "false"
  },
  {
      "industry_master_id": 27,
      "industry_name": "Voluntary groups (participation level less than 100%)",
      "allows": "false"
  },
  {
      "industry_master_id": 28,
      "industry_name": "Group policies with regard to the production, manufacture, processing, storage, transportation, use or distribution of explosives or fireworks.",
      "allows": "false"
  },
  {
    "industry_master_id": 29,
    "industry_name": "Workmen's Compensation",
    "allows": "false"
}
]

export const insurerNames = ['aiaw','medgulf-insurer','takafulemarat', 'orient', 'orienttakaful','fidelity']

export const flowsNameChanges = {
  'quote_generated':'Quote Generated',
  'Quote Generated':'Quote Generated',
  'quote_sent':'Quote Sent',
  'Send Quote':'Quote Sent',
  'Refer Insurer':'Refer to Underwriter',
  'refer_insurer':'Refer to Underwriter',
  'Refer Insurer For Discount':'Refer for Discount',
  'refer_insurer_discount':'Refer for Discount',
  'maf_uploaded':'Upload MAF Declaration',
  'Upload MAF':'Upload MAF Declaration',
  'group_uploaded':'Upload GROUP Declaration',
  'Upload Group':'Upload GROUP Declaration',
  'maf_group_uploaded':'Upload MAF & Group Declarations',
  'Upload MAF Group':'Upload MAF & Group Declarations',
  'insurer_requested_info':'Ask Further Documents',
  'Ask Further Documents':'Ask Further Documents',
  'requested_info_submitted':'Submit Documents',
  'Submit Documents':'Submit Documents',
  'revision_done':'Revise Premium',
  'Revise Premium':'Revise Premium',
  'customer_accepted':'Mark Customer Acceptance',
  'Customer Accept':'Mark Customer Acceptance',
  'policy_issued':'Proceed for Policy Issuance',
  'Proceed for Policy Issuance':'Proceed for Policy Issuance',
  'edit_quote':'Edit Quote',
  'Edit Quote':'Edit Quote',
  'chat':'Chat',
  'message':'Message',
  'Message':'Message',
  'version_changed':'Version Changed',
  'Version Changed':'Version Changed',
  'claim_uploaded':'Upload Claim Declaration',
  'Upload Claim':'Upload Claim Declaration',
  'claim_group_uploaded':'Upload Claim & Group Declarations',
  'Upload Claim Group':'Upload Claim & Group Declarations',
  'claim_maf_uploaded':'Upload Claim & MAF Declarations',
  'Upload Claim Maf':'Upload Claim & MAF Declarations',
  'claim_group_maf_uploaded':'Upload Claim & MAF & Group',
  'Upload Claim Group MAF':'Upload Claim & MAF & Group'


}
